<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Campanhas</h1>
        <!--end::Title-->
        <!--end::Title-->
        <div class="mt-3" v-if="campaigns.length > 0">
          <p class="mb-0">
            <strong>Resultados:</strong> {{ campaigns.length }}/{{ total }}
          </p>
        </div>
      </div>
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
        <!--begin::Filter menu-->
        <div class="m-0">
          <!--begin::Menu toggle-->
          <a
            href="#"
            @click.prevent="isFiltersOpen = !isFiltersOpen"
            class="btn btn-sm btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold"
          >
          <!--begin::Svg Icon | path: icons/duotune/general/gen031.svg-->
          <span class="svg-icon svg-icon-6 svg-icon-muted me-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
            </svg>
          </span>
          <!--end::Svg Icon-->Filtrar</a>
          <!--end::Menu toggle-->
          <!--begin::Menu 1-->
          <div
            class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
            :class="{
              'show': isFiltersOpen
            }"
            style="position: absolute; top: 40px; right: 0;"
          >
            <!--begin::Header-->
            <div class="px-7 py-5">
              <div class="fs-5 text-dark fw-bold">Opções de Filtro</div>
            </div>
            <!--end::Header-->
            <!--begin::Menu separator-->
            <div class="separator border-gray-200"></div>
            <!--end::Menu separator-->
            <!--begin::Form-->
            <div class="px-7 py-5">
              <!--begin::Input group-->
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Busca:</label>
                <p style="font-size: 11px;">(ID, nome, empresa ou anunciante)</p>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <input type="text" class="form-control form-control-solid" placeholder="" v-model.trim="filters.search"/>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Status:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <select class="form-select" aria-label="Select example" v-model="filters.status">
                    <option value="">Todos</option>
                    <option value="ACTIVE">Ativa</option>
                    <option value="FINISHED">Encerrada</option>
                    <option value="DRAFT">Rascunho</option>
                    <option value="PAUSED">Pausada</option>
                    <option value="ERROR">Com Erro</option>
                    <option value="PENDING">Pendente</option>
                  </select>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Valor:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="">
                      <Money
                        class="form-control mb-3"
                        v-model.trim="filters.amount_start"
                        v-bind="money"
                      />
                    </div>
                    <div class="px-4">-</div>
                    <div class="">
                      <Money
                        class="form-control mb-3"
                        v-model.trim="filters.amount_end"
                        v-bind="money"
                      />
                    </div>
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">CTR:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="">
                      <the-mask class="form-control mb-3" placeholder="0.00" mask="#.##" :masked="true" v-model.trim="filters.ctr_start" />
                    </div>
                    <div class="px-4">-</div>
                    <div class="">
                      <the-mask class="form-control mb-3" placeholder="0.00" mask="#.##" :masked="true" v-model.trim="filters.ctr_end" />
                    </div>
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Data de início:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <div class="d-flex align-items-start justify-content-between">
                    <div class="">
                      <input class="form-control form-control-solid date_picker_date" v-model.trim="filters.start_date_start" />
                      <div class="mt-3">
                        <button type="button" class="btn btn-sm btn-primary" @click="filters.start_date_start = ''" v-if="filters.start_date_start">Limpar</button>
                      </div>
                    </div>
                    <div class="px-4 mt-4">-</div>
                    <div class="">
                      <input class="form-control form-control-solid date_picker_date" v-model.trim="filters.start_date_end" />
                      <div class="mt-3">
                        <button type="button" class="btn btn-sm btn-primary" @click="filters.start_date_end = ''" v-if="filters.start_date_end">Limpar</button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Data de término:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <div class="d-flex align-items-start justify-content-between">
                    <div class="">
                      <input class="form-control form-control-solid date_picker_date" v-model.trim="filters.end_date_start" />
                      <div class="mt-3">
                        <button type="button" class="btn btn-sm btn-primary" @click="filters.end_date_start = ''" v-if="filters.end_date_start">Limpar</button>
                      </div>
                    </div>
                    <div class="px-4 mt-4">-</div>
                    <div class="">
                      <input class="form-control form-control-solid date_picker_date" v-model.trim="filters.end_date_end" />
                      <div class="mt-3">
                        <button type="button" class="btn btn-sm btn-primary" @click="filters.end_date_end = ''" v-if="filters.end_date_end">Limpar</button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Actions-->
              <div class="d-flex justify-content-end">
                <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" @click="reset">Resetar</button>
                <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true" @click="applyFilters">Aplicar</button>
              </div>
              <!--end::Actions-->
            </div>
            <!--end::Form-->
          </div>
          <!--end::Menu 1-->
        </div>
        <!--end::Filter menu-->
        <!--begin::Secondary button-->
        <!--end::Secondary button-->
      </div>
      <!--end::Actions-->
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Products-->
    <div class="card card-flush">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <template v-if="loader && campaigns.length === 0">
          <div class="py-5">
            <Loader size="big" />
          </div>
        </template>
        <template v-else-if="!loader && campaigns.length === 0">
          <p class="text-center pt-5">Nenhuma campanha encontrada</p>
        </template>
        <template v-else>
          <div class="table-responsive">
            <!--begin::Table-->
            <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
              <!--begin::Table head-->
              <thead>
                <!--begin::Table row-->
                <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                  <th class="min-w-100px">Empresa</th>
                  <th class="min-w-100px">Anunciante</th>
                  <th class="min-w-100px">Nome</th>
                  <th class="min-w-100px">Tipo</th>
                  <th class="min-w-70px">Status</th>
                  <th class="min-w-70px">Início</th>
                  <th class="min-w-70px">Término</th>
                  <th class="min-w-70px">Valor</th>
                  <th class="min-w-70px">Gasto</th>
                  <th class="min-w-70px">CTR</th>
                  <th class="min-w-70px">Ações</th>
                </tr>
                <!--end::Table row-->
              </thead>
              <!--end::Table head-->
              <!--begin::Table body-->
              <tbody class="fw-semibold text-gray-600">
                <!--begin::Table row-->
                <tr v-for="(campaign, index) in campaigns" :key="index">
                  <td>
                    <span>
                      <router-link
                        :to="{ name: 'companies.show', params: { id: campaign.company.id } }"
                      >
                        {{ campaign.company.name }}
                      </router-link>
                    </span>
                  </td>
                  <td>
                    <span>
                      <router-link
                        :to="{ name: 'advertisers.show', params: { id: campaign.advertiser.id } }"
                      >
                        {{ campaign.advertiser.name }}
                      </router-link>
                    </span>
                  </td>
                  <td>
                    <span>{{ campaign.name }}</span>
                  </td>
                  <td>
                    <span>{{ campaign.type | formatCampaignType }}</span>
                  </td>
                  <td>
                    <span>{{ campaign.status | formatStatus }}</span>
                  </td>
                  <td>
                    <span>{{ campaign.start_date | formatDate }}</span>
                  </td>
                  <td>
                    <span>{{ campaign.end_date | formatDate }}</span>
                  </td>
                  <td>
                    <span>{{ campaign.amount/100 | formatMoney(campaign.advertiser.currency) }}</span>
                  </td>
                  <td>
                    <span>{{ campaign.spending/100 | formatMoney(campaign.advertiser.currency) }}</span>
                  </td>
                  <td>
                    <span>{{ campaign.ctr }}%</span>
                  </td>
                  <td class="text-nowrap">
                    <span>
                      <router-link
                        :to="{ name: 'campaigns.show', params: { id: campaign.id } }"
                        class="btn btn-sm btn-primary"
                      >
                        <i class="fas fa-eye px-0"></i>
                      </router-link>
                      <Button
                        class="btn btn-sm btn-success ms-2"
                        v-if="campaign.type === 'YOUTUBE' && campaign.status === 'PENDING'"
                        v-b-modal="`upsert-youtube-campaign-${campaign.id}`"
                      >
                        Criar
                      </Button>
                      <UpsertYouTubeCampaignModal
                        :campaignId="campaign.id"
                        @upserted="reset(true)"
                      />
                    </span>
                  </td>
                </tr>
                <!--end::Table row-->
              </tbody>
              <!--end::Table body-->
            </table>
          </div>
          <!--end::Table-->
          <div class="text-center py-5">
            <Button
              class="btn-primary"
              :loading="loader"
              @click="loadMore"
              v-if="isLoadMoreVisible"
            >
              Carregar mais
            </Button>
          </div>
        </template>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Products-->
    <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'
import { Money } from 'v-money'
import { TheMask } from 'vue-the-mask'
import UpsertYouTubeCampaignModal from '@/components/campaigns/Modal/UpsertYouTubeCampaignModal'

export default {
  components: {
    Button,
    Loader,
    MainLayout,
    Money,
    TheMask,
    UpsertYouTubeCampaignModal
  },
  metaInfo () {
    return {
      title: 'Campanhas'
    }
  },
  data () {
    return {
      campaigns: [],
      filters: {
        amount_start: 0,
        amount_end: 0,
        ctr_start: '',
        ctr_end: '',
        end_date_end: '',
        end_date_start: '',
        search: '',
        status: '',
        start_date_end: '',
        start_date_start: ''
      },
      isFiltersOpen: false,
      isLoadMoreVisible: false,
      loader: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      page: 1,
      total: 0
    }
  },
  created () {
    this.getCampaigns()
  },
  mounted () {
    document.getElementsByClassName('date_picker_date').forEach(item => {
      item.flatpickr({
        dateFormat: 'd/m/Y'
      })
    })
  },
  methods: {
    /**
     * Apply filters and search
     */
    applyFilters () {
      this.isFiltersOpen = false
      this.page = 1
      this.campaigns = []
      this.getCampaigns()
    },
    /**
     * Get campaigns
     */
    async getCampaigns () {
      this.loader = true
      try {
        const campaigns = await CampaignsService.getCampaigns({
          search: this.filters.search,
          amount_start: this.filters.amount_start,
          amount_end: this.filters.amount_end,
          ctr_start: this.filters.ctr_start,
          ctr_end: this.filters.ctr_end,
          end_date_end: this.filters.end_date_end,
          end_date_start: this.filters.end_date_start,
          page: this.page,
          status: this.filters.status || this.$route.query.status,
          start_date_end: this.filters.start_date_end,
          start_date_start: this.filters.start_date_start,
          platform: this.$route.query.platform,
          type: this.$route.query.type
        })
        this.campaigns = [...this.campaigns, ...campaigns.data]
        this.isLoadMoreVisible = campaigns.meta.current_page !== campaigns.meta.last_page
        this.total = campaigns.meta.total
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Get the campaign platform link
     */
    getCampaignPlatformLink (campaign) {
      switch (campaign.platform) {
        case 'MGID':
          return `https://dashboard.mgid.com/advertisers/edit/campaign_id/${campaign.platform_id}`
        case 'GOOGLE':
          return `https://displayvideo.google.com/ng_nav/p/660702863/a/${campaign.advertiser.google_id}/c/${campaign.platform_id}/explorerlis`
        default:
          return ''
      }
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getCampaigns()
    },
    /**
     * Reset
     */
    reset (reload = false) {
      this.isFiltersOpen = false
      if (!reload) {
        this.filters.amount_start = 0
        this.filters.amount_end = 0
        this.filters.ctr_start = ''
        this.filters.ctr_end = ''
        this.filters.status = ''
        this.filters.end_date_end = ''
        this.filters.end_date_start = ''
        this.filters.start_date_end = ''
        this.filters.start_date_start = ''
        this.filters.search = ''
      }
      this.page = 1
      this.campaigns = []
      this.getCampaigns()
    }
  }
}
</script>
